<script>
export default {
  name: "ProjectItem"
}
</script>

<template>
<div class="w-full overflow-hidden relative" style="border-radius: 8px;">
  <img src="@/assets/images/4.png" class="w-full">
  <div class="absolute bottom-0 bg-white right-0 py-8 px-10" style="border-radius: 8px 0 0 0;">
    <p class="font-medium text-2xl">Проект 1</p>
    <p class="text-base leading-8">г. Астана</p>
  </div>
</div>
</template>

<style scoped>

</style>