<script>
export default {
  name: "ViaQR"
}
</script>

<template>
<div class="w-full flex items-center justify-center" style="padding: 40px; border-radius: 8px; border: 1px solid #F14635">
  <img src="@/assets/images/img_5.png" alt="" class="h-full">
</div>
</template>

<style scoped>

</style>