<script>
import NextBtn from "@/components/buttons/nextBtn.vue";
import PrevBtn from "@/components/buttons/prevBtn.vue";
import NewsItem from "@/pages/PressCenter/NewsItem.vue";

export default {
  name: "News",
  components: {NewsItem, PrevBtn, NextBtn},
  data() {
    return {
      news: [
        { id: 1, title: "News 1" },
        { id: 2, title: "News 2" },
        { id: 3, title: "News 3" },
        { id: 4, title: "News 4" },
        { id: 5, title: "News 5" },
        { id: 6, title: "News 6" },
      ],
    };
  },
  methods: {
    goToNewsDetail(id) {
      this.$router.push({ name: 'news-detail' }); // Переход на детальную страницу
    }
  }
}
</script>

<template>
  <div class="content">
    <div class="title">
      <h2 class="font-gilroy">
        Последние новости
      </h2>
      <div class="flex gap-4 items-center">
        <prev-btn/>
        <next-btn/>
      </div>
    </div>
    <div style="grid-template-columns: repeat(3, 1fr);display: grid" class="gap-y-2 gap-x-6">
      <div v-for="n in news" :key="n.id" class="cursor-pointer" @click="goToNewsDetail(n.id)">
        <NewsItem :title="n.title"/>
      </div>
    </div>
  </div>
</template>

<style scoped>
.content {
  @apply mx-auto;
  width: 65%;
}
.title {
  @apply w-full flex items-center justify-between;
  margin-bottom: 56px;
  h2 {
    font-weight: 500; font-size: 40px
  }
}
@media (max-width : 992px) {
  .content {
    width: 90%;
  }
  .title {
    margin-bottom: 48px;
    h2 {
      font-size: 28px;
    }
  }
}
</style>