<script>
import NextBtn from "@/components/buttons/nextBtn.vue";
import PrevBtn from "@/components/buttons/prevBtn.vue";

export default {
  name: "Information",
  components: {PrevBtn, NextBtn}
}
</script>

<template>
  <div class="main">
    <div class="mb-10">
      <img src="@/assets/images/img.png">
    </div>
    <div>
      <span style="color: #575F6C">
        Идея создания и разработка данного Портала принадлежит председателю узбекского этнокультурного центра города Астана Ш.Пулатову при непосредственной поддержки спонсоров и партнеров из числа ниже указанных компаний и организаций. Отельную благодарность выражаем этнокультурному центру города Алматы в лице председателя А.Исматуллаева за поддержку и выражение солидарности в воплощении данной инициативы. <br><br>
        Идея создания и разработка данного Портала принадлежит председателю узбекского этнокультурного центра города Астана Ш.Пулатову при непосредственной поддержки спонсоров и партнеров из числа ниже указанных компаний и организаций. Отельную благодарность выражаем этнокультурному центру города Алматы в лице председателя А.Исматуллаева за поддержку и выражение солидарности в воплощении данной инициативы. <br><br>
        Идея создания и разработка данного Портала принадлежит председателю узбекского этнокультурного центра города Астана Ш.Пулатову при непосредственной поддержки спонсоров и партнеров из числа ниже указанных компаний и организаций. Отельную благодарность выражаем этнокультурному центру города Алматы в лице председателя А.Исматуллаева за поддержку и выражение солидарности в воплощении данной инициативы.
      </span>
    </div>
  </div>
</template>

<style scoped>
.main {
  padding-bottom: 80px;

  img  {
    width: 100%;
    @apply rounded;
  }
}
span {
  @apply text-base leading-8;
}
@media (max-width : 992px) {
  .main {
    padding-bottom: 48px;

  }
  span {
    font-size: 14px;
    line-height: 28px;
  }
}
</style>